.register-bg {
  background: url('../../../assets/images/auth-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.register-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
